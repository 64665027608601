import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { BlogEntry } from "../../../components";
import { DesktopRightImage, PhoneImage } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Not only bears and wolves hide in the woods of Slovenia. When you have a
closer look, you will also find a lot of trails. Not very obvious, as it is
still forbidden to ride in many places. But the situation improves and the
potential is huge. In the south, close to Croatia, you can even ride in
wintertime. The bike community is strong and takes care a lot of their trails.
You will most likely find a friendly local showing you his trails.`}</p>
    <br />
    <Link className="text-black" to="/destination/slovenia/koroska" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/koroska_13.jpg" mdxType="DesktopRightImage" />
        <h2>{`Koroška`}</h2>
        <h4>{`From the sky to the the center of the earth.`}</h4>
        <p>{`Over endless ridges, into the deep valleys of Koroška. The idyllic trail park
of Jamnica, the long descents from mount Petzen. The small bike parks of Kope
and Poseka, or the underground biking on the Black Hole Trail. Not much places
offer such a variety.`}</p>
        <PhoneImage src="destinations/koroska_13.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/slovenia/socavalley" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/bovec1.jpg" mdxType="DesktopRightImage" />
        <h2>{`Soca Valley`}</h2>
        <h4>{`A fairy-tale of elfes and trails`}</h4>
        <p>{`In the north-west of Slovenia the Soca river rises in the high mountains
behind Bovec. The crystal clear water, cataracts and canyons are world
famous for white water kayaking. In the beautiful valley you also find
many great and wild singletracks.`}</p>
        <PhoneImage src="destinations/bovec1.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      